import axios from "axios";

const MAX_REQUESTS_COUNT = 10;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

const API = axios.create({
    baseURL: "https://payments.bahiaisidoro.cl/bookisityapi" //process.env.NODE_ENV === "development" ? "http://localhost:3001/" : process.env.REACT_APP_API_URL,
});

API.interceptors.request.use((config) => {
    const access_token ='uiodbuipdbidbfsdfdf';
        config.headers.Authorization = `Bearer ${access_token}`;
    return config;
});
/**
 * Axios Request Interceptor
 */
API.interceptors.request.use(function (config) {
    return new Promise((resolve) => {
        let interval = setInterval(() => {
            if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                PENDING_REQUESTS += 1;
                clearInterval(interval);
                resolve(config);
            }
        }, INTERVAL_MS);
    });
});
/**
 * Axios Response Interceptor
 */
API.interceptors.response.use(
    (response) => {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        return Promise.resolve(response);
    },
    (error) => {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        return Promise.reject(error);
    }
);

export default API;
