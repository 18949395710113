import React from 'react'
import { FormAvailabilityComponent } from '../forms/FormAvailabilityComponent'
import { useNavigate } from 'react-router-dom'
import SliderComponent from '../slider/SliderComponent'

export const BookingsReserveComponent = (props) => {
    const navigate = useNavigate();

    const handleVolverAtras = () => {
        navigate(-1);
    };

    return (
        <>
            <div className='button-container'>
                <button onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
                    <span className="button-icon-reserve"></span>
                    <p className="button-text-reserve">Volver</p>
                </button>
            </div>
            <div className='content-desktop'>
                <div className='bookings-content'>
                    <div className='info'>
                        <div className='div' dangerouslySetInnerHTML={{ __html: props.data.title }} />
                        {props.data.description.map((desc, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: desc }} />
                        ))}
                        <div className='divider'></div>
                        <div className='alert alert-info' style={{ margin: '40px 0 0' }}>
                            <span className='symbol'></span>
                            <div className='text'>
                                <div dangerouslySetInnerHTML={{ __html: props.data.alert }} />
                            </div>
                        </div>
                        <div className='form'>
                            <p className='form-title'>Reserva online</p>
                            <FormAvailabilityComponent />
                        </div>
                    </div>
                </div>
                <div className='content-right'>
                    <SliderComponent />
                    <div className='div-content-right' style={{ padding: '0 16px' }}>
                        <div className='div' dangerouslySetInnerHTML={{ __html: props.data.subtitle }} />
                        {props.data.descriptionSubtitle.map((desc, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: desc }} />
                        ))}
                        <div className='services'>
                            {props.data.servicesIcon.map((service, index) => (
                                <div className='service' key={index}>
                                    <div className='icon' dangerouslySetInnerHTML={{ __html: service.icon }} />
                                    <p dangerouslySetInnerHTML={{ __html: service.text }} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='bookings-wrapper content-mobile'>
                <div className='bookings-title'>
                    <div className='info'>
                        <div className='div' dangerouslySetInnerHTML={{ __html: props.data.title }} />
                        {props.data.description.map((desc, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: desc }} />
                        ))}
                    </div>
                </div>
                <div className='bookings-content'>
                    <div className='info'>
                        <div className='divider'></div>
                        <div className='alert alert-info' style={{ margin: '40px 0 0' }}>
                            <span className='symbol'></span>
                            <div className='text'>
                                <div dangerouslySetInnerHTML={{ __html: props.data.alert }} />
                            </div>
                        </div>
                        <div className='title-reserve'>
                            <p className='form-title'>Reserva online</p>
                        </div>
                        <div className='form'>
                            <FormAvailabilityComponent />
                        </div>
                    </div>
                </div>
                <div className='bookings-image'>
                    <SliderComponent />
                </div>
                <div className="content-right">
                    <div className='div-content-right' style={{ padding: '0 16px' }}>
                        <div className='div' dangerouslySetInnerHTML={{ __html: props.data.subtitle }} />
                        {props.data.descriptionSubtitle.map((desc, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: desc }} />
                        ))}
                        <div className='services'>
                            {props.data.servicesIcon.map((service, index) => (
                                <div className='service' key={index}>
                                    <div className='icon' dangerouslySetInnerHTML={{ __html: service.icon }} />
                                    <p dangerouslySetInnerHTML={{ __html: service.text }} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}