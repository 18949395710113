import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

export const NoFacilitiesView = () => {
    const navigate = useNavigate();

    const handleVolverAtras = () => {
        navigate(-1);
    };
    return (
        <>
        <button style={{ marginTop: '24px' }} onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
            <span class="button-icon-message"></span>
            <p class="button-text">Volver</p>
        </button>
        <div className='divider' id='divider-message'></div>
        <div className='error-container'>
            <div>
                <svg width="118" height="112" viewBox="0 0 118 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_712_3913)">
                        <path d="M42 9.33398C43.2377 9.33398 44.4247 9.82565 45.2998 10.7008C46.175 11.576 46.6667 12.763 46.6667 14.0007V18.6673H65.3333V14.0007C65.3333 12.763 65.825 11.576 66.7002 10.7008C67.5753 9.82565 68.7623 9.33398 70 9.33398C71.2377 9.33398 72.4247 9.82565 73.2998 10.7008C74.175 11.576 74.6667 12.763 74.6667 14.0007V18.6673H88.6667C91.142 18.6673 93.516 19.6506 95.2663 21.401C97.0167 23.1513 98 25.5253 98 28.0007V88.6673C98 91.1427 97.0167 93.5166 95.2663 95.267C93.516 97.0173 91.142 98.0007 88.6667 98.0007H23.3333C20.858 98.0007 18.484 97.0173 16.7337 95.267C14.9833 93.5166 14 91.1427 14 88.6673V28.0007C14 25.5253 14.9833 23.1513 16.7337 21.401C18.484 19.6506 20.858 18.6673 23.3333 18.6673H37.3333V14.0007C37.3333 12.763 37.825 11.576 38.7002 10.7008C39.5753 9.82565 40.7623 9.33398 42 9.33398ZM37.3333 28.0007H23.3333V42.0007H88.6667V28.0007H74.6667V32.6673C74.6667 33.905 74.175 35.092 73.2998 35.9671C72.4247 36.8423 71.2377 37.334 70 37.334C68.7623 37.334 67.5753 36.8423 66.7002 35.9671C65.825 35.092 65.3333 33.905 65.3333 32.6673V28.0007H46.6667V32.6673C46.6667 33.905 46.175 35.092 45.2998 35.9671C44.4247 36.8423 43.2377 37.334 42 37.334C40.7623 37.334 39.5753 36.8423 38.7002 35.9671C37.825 35.092 37.3333 33.905 37.3333 32.6673V28.0007ZM88.6667 51.334H23.3333V88.6673H88.6667V51.334Z" fill="#9B9B9B" />
                        <path d="M117.803 88.0008C117.803 101.146 107.147 111.802 94.0017 111.802C80.8564 111.802 70.2 101.146 70.2 88.0008C70.2 74.8556 80.8564 64.1992 94.0017 64.1992C107.147 64.1992 117.803 74.8556 117.803 88.0008Z" fill="white" />
                        <path d="M118.2 88.1994C118.2 94.5646 115.671 100.669 111.171 105.17C106.67 109.671 100.565 112.2 94.2 112.2C87.8348 112.2 81.7303 109.671 77.2295 105.17C72.7286 100.669 70.2 94.5646 70.2 88.1994C70.2 81.8342 72.7286 75.7296 77.2295 71.2287C81.7303 66.7278 87.8348 64.1992 94.2 64.1992C100.565 64.1992 106.67 66.7278 111.171 71.2287C115.671 75.7296 118.2 81.8342 118.2 88.1994ZM94.2 72.9266C93.6214 72.9266 93.0664 73.1564 92.6572 73.5656C92.2481 73.9748 92.0182 74.5297 92.0182 75.1084V92.5631C92.0182 93.1417 92.2481 93.6967 92.6572 94.1059C93.0664 94.515 93.6214 94.7449 94.2 94.7449C94.7787 94.7449 95.3336 94.515 95.7428 94.1059C96.152 93.6967 96.3818 93.1417 96.3818 92.5631V75.1084C96.3818 74.5297 96.152 73.9748 95.7428 73.5656C95.3336 73.1564 94.7787 72.9266 94.2 72.9266ZM94.2 104.018C94.9233 104.018 95.617 103.73 96.1285 103.219C96.6399 102.707 96.9273 102.014 96.9273 101.29C96.9273 100.567 96.6399 99.8734 96.1285 99.3619C95.617 98.8505 94.9233 98.5631 94.2 98.5631C93.4767 98.5631 92.783 98.8505 92.2715 99.3619C91.7601 99.8734 91.4727 100.567 91.4727 101.29C91.4727 102.014 91.7601 102.707 92.2715 103.219C92.783 103.73 93.4767 104.018 94.2 104.018Z" fill="#F99509" />
                    </g>
                    <defs>
                        <clipPath id="clip0_712_3913">
                            <rect width="118" height="112" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <p className='title'>En este momento, no tenemos disponibilidad de reservas.</p>
            <p className='subtitle'>Por favor, contáctanos para poder atender tu solicitud.</p>

            <div className='buttons'>
                <Link className='bi-button-secondary' to={'/'}>Ir al home</Link>
                <Link className='bi-button-primary' to={'/contacto'}>Ir a contacto</Link>
            </div>
        </div>
        </>
    )
}
