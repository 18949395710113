import React, { useState, useEffect, useRef } from 'react';

export const SingleSelectComponent = ({ options, onSelectChange, isDisabled, tipoFacilitySelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const selectRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedValue(option.value);
    setSelectedLabel(option.option);
    setIsOpen(false);
    if (onSelectChange) {
      onSelectChange(option.value);
    }
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (tipoFacilitySelected) {
      setSelectedValue(null)
      setSelectedLabel('Selecciona');
    }
  }, [tipoFacilitySelected]);

  return (
    <div className='selectbox' ref={selectRef}>
      <p className='title-selectbox'>{options[0].title}</p>
      <div className="selectbox">
        <div className="select" onClick={toggleDropdown}>
          <p>{selectedLabel || options[0].label}</p>
          <span className={isOpen && !isDisabled ? 'arrow-up' : 'arrow-down'}></span>
        </div>
        {isOpen && !isDisabled && (
          <ul className="options">
            {options.map((option) => (
              <li className='option' key={option.value} onClick={() => handleOptionClick(option)}>
                {option.option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};