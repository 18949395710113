import React, { useEffect, useState } from 'react'
import { AvailabilityComponent } from '../components/availability/AvailabilityComponent'
import { ServicesContentComponent } from '../components/content/ServicesContentComponent'
import { useSelector } from 'react-redux'
import { ButtonReserveComponent } from '../components/globals/ButtonReserveComponent'

export const ServicesView = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [configuration, setConfiguration] = useState({})
  const dataRedux = useSelector((state) => state.global);

  useEffect(() => {
    window.scrollTo(0, 0);
    setData(dataRedux.services)
    setConfiguration(dataRedux.configuration)
    setLoading(false);
  }, [data, configuration, dataRedux])


  if (isLoading) { return <div className='loader'><span className='loading'></span></div> }

  return (
    <>
    <div className='services-container'>
      <AvailabilityComponent />
      <ServicesContentComponent data={data} configuration={configuration} />
    </div>
    < ButtonReserveComponent withButtonSecondary={false} />
    </>
  )
}