import React, { useEffect, useState } from 'react'
import { BookingsReserveComponent } from '../../components/bookings/BookingsReserveComponent';
import { useSelector } from 'react-redux';
import { ButtonReserveComponent } from '../../components/globals/ButtonReserveComponent'

export const BookingsReserveView = () => {
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const dataRedux = useSelector((state) => state.global);

    useEffect(() => {
        window.scrollTo(0, 0);
        setData(dataRedux.bookings)
        setLoading(false);
    }, [data, dataRedux])


    if (isLoading) { return <div className='loader'><span className='loading'></span></div> }

    return (
        <>
            <div className='stepper-mobile stepper1'>
                <div className='stepper-wrapper-mobile'>
                    <div className='stepper-fixed'>
                        <p className='title-reserve-component'>Reservas</p>
                        <div className='stepper'>
                            <div className='bar bar1'></div>
                            <div className='bar bar2'></div>
                            <div className='bar bar3'></div>
                        </div>
                    </div>
                    <div className='text'>
                        <p className='text1'>Selecciona la instalación</p>
                        <p className='text2'>Completa los datos</p>
                        <p className='text3'>¡Está lista tu reserva!</p>
                    </div>
                </div>
            </div>
            <div className="bookings-container">
                <div className='stepper-wrapper'>
                    <div className='stepper'>
                        <div className='step1'></div>
                        <div className='bar bar1'></div>
                        <div className='step2'></div>
                        <div className='bar bar2'></div>
                        <div className='step3'></div>
                    </div>
                    <div className='text'>
                        <p className='text1'>Selecciona la instalación</p>
                        <p className='text2'>Completa los datos</p>
                        <p className='text3'>¡Está lista tu reserva!</p>
                    </div>
                </div>
                <BookingsReserveComponent data={data} />
            </div>
            < ButtonReserveComponent withButtonSecondary={false} />
        </>
    )
}