import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBook, getUserDetail } from '../../services';
import { nameAndLastnameSeparate } from '../../utils/utils';

export const FormReserveComponent = (props) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [rut, setRut] = useState('');
    const [phone, setPhone] = useState('');
    const [notes, setNotes] = useState('');
    const [dataFacility, setDataFacility] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.data) {
            setDataFacility(props.data);
        }
    }, []);

    useEffect(() => {
        const isValid = email && name && lastname && rut && phone;
        setIsFormValid(isValid);
    }, [email, name, lastname, rut, phone]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleLastnameChange = (e) => {
        setLastname(e.target.value);
    };

    const handleRutChange = (e) => {
        setRut(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const goToReserve = async (e) => {
        e.preventDefault();

        console.log("data facility", props.facility);
        console.log("data daysDifference", props.daysDifference);
        console.log("data price", props.price);

        const dataReserve = JSON.parse(sessionStorage.getItem('dataReserve'));
        if (dataReserve) {
            dataReserve.email = email;
            dataReserve.nombres = name;
            dataReserve.apellidos = lastname;
            dataReserve.rut = rut;
            dataReserve.telefono = phone;
            dataReserve.notas = notes;
            dataReserve.facility = props.facility;
            dataReserve.daysDifference = props.daysDifference;
            dataReserve.price = props.price;
            sessionStorage.setItem('dataReserve', JSON.stringify(dataReserve));
            navigate('/reservas/confirmar');
        } else {
            navigate('//error/instalaciones');
        }
    };

    const goToReserveBookisity = async (e) => {
        e.preventDefault();
        const dataReserve = JSON.parse(sessionStorage.getItem('dataReserve'));
        const response = await getBook({
            from: dataReserve.entrada,
            to: dataReserve.salida,
            tipo: dataReserve.tipo,
            instalaciones: dataReserve.numTipo,
            adultos: dataReserve.adultos,
            ninos: dataReserve.ninos,
            notes: notes,
            pasaporte: rut,
            nombres: `${name} ${lastname}`,
            email: email,
            telefono: phone
        });

        if (!response) {
            navigate('/error/instalaciones')
        }
    };

    const getUserDetailUser = async (id) => {
        const response = await getUserDetail({ pasaporte: id });
        if (response.status === 1) {
            const nombreCompleto = nameAndLastnameSeparate(response.data.nombres);
            setName(nombreCompleto.nombres);
            setEmail(response.data.email);
            setLastname(nombreCompleto.apellidos);
            setPhone(response.data.telefono);
            document.getElementById('name').value = nombreCompleto.nombres;
            document.getElementById('lastname').value = nombreCompleto.apellidos;
            document.getElementById('email').value = response.data.email;
            document.getElementById('phone').value = response.data.telefono;
        }
    }

    return (
        <div className='form-reserve-wrapper'>
            <form>
                <div className='group-form'>
                    <div className='input-wrapper'>
                        <label htmlFor="rut">RUT ó Pasaporte</label>
                        <input type="text" id="rut" name="rut" placeholder="RUT ó Pasaporte" onChange={handleRutChange} onBlur={() => getUserDetailUser(rut)} />
                    </div>
                </div>
                <div className='group-form'>
                    <div className='input-wrapper'>
                        <label htmlFor="name">Nombres</label>
                        <input type="text" id="name" name="name" placeholder="Nombres" onChange={handleNameChange} />
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="lastname">Apellidos</label>
                        <input type="text" id="lastname" name="lastname" placeholder="Apellidos" onChange={handleLastnameChange} />
                    </div>
                </div>
                <div className='group-form'>
                    <div className='input-wrapper'>
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" placeholder="Email" onChange={handleEmailChange} />
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="phone">Teléfono de contacto</label>
                        <input type="text" id="phone" name="phone" maxLength={12} placeholder="Teléfono" onChange={handlePhoneChange} />
                    </div>
                </div>

                <button className='bi-button-primary' id='button-goToReserve' onClick={goToReserveBookisity} disabled={!isFormValid}>Continuar</button>
            </form>
        </div>
    )
}