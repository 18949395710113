import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/HomeView';
import { FacilitiesView } from '../pages/facilities/FacilitiesView';
import { ServicesView } from '../pages/ServicesView';
import { PricesView } from '../pages/PricesView';
import { ContactView } from '../pages/contact/ContactView';
import { HeaderComponent } from '../components/header/HeaderComponent';
import { FacilitiesListView } from '../pages/facilities/FacilitiesListView';
import { FacilitiesSelectView } from '../pages/facilities/FacilitiesSelectView';
import { FacilitiesReserveView } from '../pages/facilities/FacilitiesReserveView';
import { NoFacilitiesView } from '../pages/error/NoFacilitiesView';
import { StoryView } from '../pages/contact/StoryView';
import { ContactUsView } from '../pages/contact/ContactUsView';
import { BookingsView } from '../pages/bookings/BookingsView';
import { BookingsReserveView } from '../pages/bookings/BookingsReserveView';
import { BookingsConfirmView } from '../pages/bookings/BookingsConfirmView';
import { ContactButtonsComponent } from '../components/globals/ContactButtonsComponent';
import { GenericError } from '../pages/error/GenericError';
import { MessageErrorComponent } from '../components/contact/MessageErrorComponent';
import { MessageSuccessComponent } from '../components/contact/MessageSuccessComponent';
import { FooterComponent } from '../components/footer/FooterComponent';

export const RouterOutlet = () => {
  return (
    <BrowserRouter>
      <HeaderComponent />
      <ContactButtonsComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/inicio" element={<Home />} />
        <Route path="/instalaciones/*" element={<FacilitiesView />}>
          <Route path="" element={<FacilitiesListView />} />
          <Route path="seleccionar/:id" element={<FacilitiesSelectView />} />
          <Route path="reservar" element={<FacilitiesReserveView />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
        <Route path="/contacto/*" element={<ContactView />}>
          <Route path="" element={<ContactUsView />} />
          <Route path="nosotros" element={<StoryView />} />
          <Route path="error" element={<MessageErrorComponent />} />
          <Route path="enviado" element={<MessageSuccessComponent />} />
        </Route>
        <Route path="/reservas/*" element={<BookingsView />}>
          <Route path="" element={<BookingsReserveView />} />
          <Route path="confirmar" element={<BookingsConfirmView />} />
        </Route>
        <Route path="/servicios" element={<ServicesView />} />
        <Route path="/valores" element={<PricesView />} />
        <Route path="/nosotros" element={<ContactView />} />
        <Route path="/error" element={<GenericError />} />
        <Route path="/error/instalaciones" element={<NoFacilitiesView />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    <FooterComponent />
    </BrowserRouter>
  )
}