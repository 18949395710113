import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormReserveComponent } from '../forms/FormReserveComponent';
import { formatDateToDDMMYYYY, getDaysDifference, priceToCLP } from '../../utils/utils';
import { getPricesOfService } from '../../services';

export const ReserveContentComponent = (props) => {
  const [isLoading, setLoading] = useState(true)
  const location = useLocation();
  const navigate = useNavigate();
  const [datosReserva, setDatosReserva] = useState(null);
  const [facility, setFacility] = useState({});
  const [selectedImages, setSelectedImages] = useState({});
  const [price, setPrice] = useState('');
  const [daysDifference, setDaysDifference] = useState(0);
  var index = 0;

  const handleVolverAtras = () => {
    navigate(-1);
  };

  useEffect(() => {
    setLoading(true)
    const datosAlmacenados = JSON.parse(sessionStorage.getItem('dataReserve'));
    setDatosReserva(datosAlmacenados);
    const daysDifference = datosAlmacenados && getDaysDifference(datosAlmacenados.entrada, datosAlmacenados.salida);
    setDaysDifference(daysDifference);
  }, [props.facilities, location]);

  useEffect(() => {
    if (datosReserva) {
      const payload = {
        "from": datosReserva.entrada,
        "to": datosReserva.salida,
        "tipo": datosReserva.tipo,
        "instalaciones": datosReserva.numTipo,
        "adultos": datosReserva.adultos,
        "ninos": datosReserva.ninos,
      }
      const findFacilityById = async () => {
        const response = await getPricesOfService(payload);
        if (response.data.totalReserva) {
          setPrice(response.data.totalReserva);
          setTimeout(() => {
            setLoading(false);
          }, 1000)
        } else {
          navigate('/error');
        }
      }
      findFacilityById();
    }
  }, [datosReserva])

  useEffect(() => {
    if (datosReserva) {
      const findFacilityById = () => {
        if (props.facilities && props.facilities) {
          const foundFacility = props.facilities.find(item => item.id === datosReserva.tipo);
          if (foundFacility) {
            setFacility(foundFacility);
          }
        } else {
          navigate('/error');
        }
      };
      findFacilityById();
    }
  }, [datosReserva, daysDifference]);

  const handleImageClick = (sectionIndex, selectedImage) => {
    setSelectedImages((prevSelectedImages) => ({
      ...prevSelectedImages,
      [sectionIndex]: selectedImage,
    }));
  };

  return (
    <>
      {isLoading && <div className='loader'><span className='loading'></span></div>}
      <div className='stepper-wrapper stage2'>
        <div className='stepper'>
          <div className='step1'></div>
          <div className='bar bar1'></div>
          <div className='step2'></div>
          <div className='bar bar2'></div>
          <div className='step3'></div>
        </div>
        <div className='text'>
          <p className='text1'>Selecciona la instalación</p>
          <p className='text2'>Completa los datos</p>
          <p className='text3'>¡Está lista tu reserva!</p>
        </div>
      </div>
      <div className='button-container-back'>
        <button onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
          <span class="button-icon-content"></span>
          <p class="button-text-content">Volver</p>
        </button>
      </div>
      <div className='alert alert-info' style={{ margin: '0 0 32px' }}>
        <span className='symbol' style={{ width: '40px' }}></span>
        <div className='text'>
          <div dangerouslySetInnerHTML={props.data.alert && { __html: props.data.alert.subtitle }} />
        </div>
      </div>
      <div className='facilities-reserve-content'>
        <div className='facilities'>
          <div className='facility'>
            <div className='information' id='information-data' style={{ marginRight: '24px' }}>
              <p className='title-info'>Completa tus datos</p>
              <div className='formt-content'>
                <FormReserveComponent data={datosReserva} facility={facility} daysDifference={daysDifference} price={price} />
              </div>
            </div>
            <div className='information' id='information-booking'>
              <p className='title-mobile' dangerouslySetInnerHTML={{ __html: facility.title }} />
              <div className='desc-content content-mobile'>
                <div className='desc-content-left'>
                  <img src={selectedImages[index] || (facility.imageGalery && facility.imageGalery[0])} />
                </div>
                <div className='desc-content-right'>
                  {facility.characteristics && facility.characteristics.map((characteristic, index) => (
                    <div className='characteristics' key={index}>
                      <span dangerouslySetInnerHTML={{ __html: characteristic.icon }} />
                      <p dangerouslySetInnerHTML={{ __html: characteristic.text }} />
                    </div>
                  ))}
                </div>
              </div>
              <div className='desc-content content-desktop'>
                <img src={selectedImages[index] || (facility.imageGalery && facility.imageGalery[0])} />
                <div>
                  <p dangerouslySetInnerHTML={{ __html: facility.title }} />

                  {facility.characteristics && facility.characteristics.map((characteristic, index) => (
                    <div className='characteristics' key={index}>
                      <span dangerouslySetInnerHTML={{ __html: characteristic.icon }} />
                      <p dangerouslySetInnerHTML={{ __html: characteristic.text }} />
                    </div>
                  ))}
                </div>
              </div>
              <div className='divider' style={{ margin: '16px 0' }}></div>
              <div className='content-price'>
                <p className='bold' style={{ marginBottom: '16px' }}>Tu viaje</p>
                <div className='detail-group'>
                  <p className='light'>Llegada</p>
                  <p className='bold'>{datosReserva && formatDateToDDMMYYYY(datosReserva.entrada)}</p>
                </div>
                <div className='detail-group'>
                  <p className='light'>Salida</p>
                  <p className='bold'>{datosReserva && formatDateToDDMMYYYY(datosReserva.salida)}</p>
                </div>
                <div className='detail-group'>
                  <p className='light'>Número de instalaciones</p>
                  <p className='bold'>
                    {datosReserva && datosReserva.numTipo}
                  </p>
                </div>
                <div className='detail-group'>
                  <p className='light'>Cantidad de personas</p>
                  <p className='bold'>
                    {`${datosReserva && datosReserva.adultos} adultos, ${datosReserva && datosReserva.ninos} niños`}
                  </p>
                </div>
              </div>
              <div className='content-price'>
                <p className='bold' style={{ marginBottom: '16px' }}>Información del precio</p>
                <div className='detail-group' style={{ margin: '0' }}>
                  {/* <p className='light'>{`${priceToCLP(price / daysDifference)} x ${daysDifference} noches`}</p> */}
                  <p className='light'>{`Precio final x ${daysDifference} ${daysDifference === 1 ? 'noche' : 'noches'}`}</p>
                  <p className='bold'>{priceToCLP(price)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
