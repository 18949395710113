import React, { useEffect, useState } from 'react'
import { AvailabilityComponent } from '../components/availability/AvailabilityComponent';
import { PricesContentComponent } from '../components/content/PricesContentComponent';
import { CompaniesContentComponent } from '../components/content/CompaniesContentComponent';
import { getAllPrices } from '../services';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonReserveComponent } from '../components/globals/ButtonReserveComponent'

export const PricesView = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [prices, setPrices] = useState({})
  const dataRedux = useSelector((state) => state.global);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setData(dataRedux.values)
  }, [data, dataRedux])

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAllPrices();
      console.log("response getAllPrices", response);
      if (response) {
        setPrices(response);
      } else {
        navigate('/error');
      }
      setLoading(false);
    }
    fetchData();
  }, [])


  if (isLoading) { return <div className='loader'><span className='loading'></span></div> }

  return (
    <>
      <div className='prices-container'>
        <AvailabilityComponent />
        <PricesContentComponent data={data} prices={prices} />
        <CompaniesContentComponent data={data.companies} />
      </div>
      < ButtonReserveComponent withButtonSecondary={false} />
    </>
  )
}