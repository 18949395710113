import React, { useEffect, useState } from 'react'
import { priceToCLP, stringToHTML } from '../../utils/utils';

export const ValuesComponentMobile = ({ data }) => {

    const [expanded, setExpanded] = useState(null);

    const handleToggle = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    const isCurrentDateInRange = (startDateStr, endDateStr) => {
        const currentDate = new Date();
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
    
        return currentDate >= startDate && currentDate <= endDate;
    };
    
    useEffect(() => {
        const currentItemIndex = data.findIndex(item => 
            isCurrentDateInRange(item.fecha_inicio, item.fecha_fin)
        );
    
        if (currentItemIndex !== -1) {
            setExpanded(currentItemIndex);
        }
    }, [data]);    

    const formatDates = (fechaInicioStr, fechaFinStr) => {
        const meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
        const fechaInicio = new Date(fechaInicioStr);
        const fechaFin = new Date(fechaFinStr);

        const diaInicio = fechaInicio.getDate();
        const mesInicio = meses[fechaInicio.getMonth()];

        const diaFin = fechaFin.getDate();
        const mesFin = meses[fechaFin.getMonth()];

        return `Del ${diaInicio} de ${mesInicio} al ${diaFin} de ${mesFin}`;
    };

    const renderCard = (item, index) => {
        const fechaFormateada = formatDates(item.fecha_inicio, item.fecha_fin);
        const isOpen = expanded === index;

        return (
            <div className='wrapper' key={index} style={{ marginBottom: '10px', border: expanded === index ? '1px solid #ff6102' : '1px solid #757575' }}>
                <div className='title-wrapper' style={{ cursor: 'pointer', padding: '16px' }} onClick={() => handleToggle(index)}>
                <div className='title-wrapper-mobile' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px' }}>
                        {item.Tnombre}
                        <span className={isOpen ? 'arrow-up' : 'arrow-down'} style={{ transform: 'scale(1.5)' }}></span>
                    </div>
                    <div className='date-wrapper-mobile'>
                        *{fechaFormateada}
                    </div>
                </div>
                <div className='content-wrapper'
                    style={{ display: expanded === index ? 'block' : 'none', backgroundColor: '#fff' }}>
                    <div className='divider'></div>
                    {item.valores.map((value, valueIndex) => (
                        <div key={valueIndex} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <div>
                                {value.instalacion.split(' (').map((part, partIndex, array) => (
                                    <React.Fragment key={partIndex}>
                                        {partIndex === 0 ? part : `(${part}`}
                                        {partIndex < array.length - 1 && <br />}
                                    </React.Fragment>
                                ))}
                            </div>
                            <strong>$ {value.valor.toString().slice(0, -3)} x noche</strong>
                        </div>
                    ))}
                </div>

            </div>
        );
    };

    return (
        <div className='values-wrapper-mobile'>
            {data.map(renderCard)}
        </div>
    );
};
