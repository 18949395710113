import React from 'react'
import { Link } from 'react-router-dom'

export const CompaniesContentComponent = (props) => {
    return (
        <>
            <div className='companies-content'>
                <div dangerouslySetInnerHTML={{ __html: props.data.title }} />
                <div className='companie'>
                    {props.data.icons.map((companies, index) => (
                        // <div key={index} dangerouslySetInnerHTML={{ __html: companies }} />
                        <img key={index} src={companies}></img>
                    ))}
                </div>
            </div>
            <div className='button-container'>
                <Link to={'/reservas'} className='bi-button-primary'>Quiero reservar</Link>
            </div>
        </>
    )
}
