import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendEmail } from '../../services';
import { ButtonReserveComponent } from '../globals/ButtonReserveComponent'

export const ContactUsContentComponent = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleVolverAtras = () => {
    navigate(-1);
  };

  const handleCopyDirectionsLink = () => {
    const iframe = document.getElementById('googleMap');
    const googleMapsUrl = iframe.src;
    openNewWindow(googleMapsUrl);
  };

  const openNewWindow = (url) => {
    window.open(url, '_blank');
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    validateForm();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateForm();
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    validateForm();
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    validateForm();
  };

  const validateForm = () => {
    const isValid = name.trim() !== '' && email.trim() !== '' && subject.trim() !== '' && message.trim() !== '';
    setIsFormValid(isValid);
  };

  const handleEnviarClick = async () => {
    setIsLoading(true);

    const response = await sendEmail(name.trim(), email.trim(), subject.trim(), message.trim());
    if (response.statusCode === 200) {
      navigate('/contacto/enviado');
    } else {
      navigate('/contacto/error');
    }

    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
  };

  return (
    <>
      <div className='button-container-back'>
        {isLoading && <div className='loader'><span className='loading'></span></div>}

        <div className='mobile-services'>
            <div className='button-container-back mobile'>
                <button onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
                    <span class="button-icon-contact"></span>
                </button>
            </div>
            <p className='title mobile'>Contáctanos</p>
        </div>

        <button onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
          <span></span>
          Volver
        </button>
        <div className='contact-us-content'>
          <div className='box'>
            <p className='title desktop'>Contáctanos</p>
            <div className='form'>
              <div className='input-wrapper'>
                <label htmlFor="name">Nombres <span className='orange'>*</span></label>
                <input type="text" id="name" name="name" placeholder='Nombres *' onChange={handleNameChange} />
              </div>
              <div className='input-wrapper'>
                <label htmlFor="email">Email <span className='orange'>*</span></label>
                <input type="email" id="email" name="email" placeholder='Email *' onChange={handleEmailChange} />
              </div>
              <div className='input-wrapper'>
                <label htmlFor="subject">Asunto <span className='orange'>*</span></label>
                <input type="text" id="subject" name="subject" placeholder='Asunto *' onChange={handleSubjectChange} />
              </div>
              <div className='input-wrapper' style={{ marginBottom: '16px' }}>
                <label htmlFor="message">Mensaje <span className='orange'>*</span></label>
                <textarea style={{ fontFamily: 'inherit', fontSize: '14px' }} id="message" name="message" placeholder='Mensaje *' onChange={handleMessageChange}></textarea>
              </div>
              <p className='light' style={{ marginBottom: '32px' }}><span className='orange'>*</span> Campos obligatorios</p>
              <button className='bi-button-primary' onClick={handleEnviarClick} disabled={!isFormValid}>
                Enviar
              </button>
            </div>
          </div>
          <div className='box'>
            <p className='title' id='title-map'>Estamos ubicados en</p>
            <p className='light' id='light-map' >Revise <button className='orange' style={{ cursor: 'pointer' }} onClick={handleCopyDirectionsLink}>aqui</button> cómo llegar desde su punto de partida.</p>
            <div className='map' dangerouslySetInnerHTML={{ __html: props.configuration.mapa }} />
            <div className='divider' id='divider-contact' style={{ borderColor: '#FF6200' }}></div>
            <p className='title' id='title-contact'>Datos de contacto </p>
            <p className='light orange'>Dirección</p>
            <p className='light'>{props.data.address}</p>
            <p className='light orange'>Teléfonos</p>
            {props.data.phones.map((phone, index) => (
              <p className='light' key={index}>{phone}</p>
            ))}
          </div>
        </div>
      </div>
      < ButtonReserveComponent withButtonSecondary={false} />
    </>
  );
};
