import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const ContactButtonsComponent = () => {
    const [data, setData] = useState({})
    const dataRedux = useSelector((state) => state.global);

    useEffect(() => {
        window.scrollTo(0, 0);
        setData(dataRedux.configuration)
    }, [data, dataRedux])

    return (
        <div className='contact-buttons'>
            <Link to={'/contacto'}>
                <button className='bi-button-email'></button>
            </Link>
            <a href={`https://wa.me/${data.whatsapp}`} target='_blank'>
                <button className='bi-button-whatsapp'></button>
            </a>
        </div>
    )
}