import React from 'react'
import { ValuesComponent } from '../values/ValuesComponent'
import { ValuesComponentMobile } from '../values/ValuesComponentMobile'
import { Link, useNavigate } from 'react-router-dom'

export const PricesContentComponent = (props) => {
    const navigate = useNavigate();

    const handleVolverAtras = () => {
        navigate(-1);
    };

    return (
        <>
            <div className='mobile-services'>
                <div className='button-container-back mobile'>
                    <button onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
                        <span class="button-icon-values"></span>
                    </button>
                </div>
                <p className='title-container-back mobile'>Valores</p>
            </div>

            <div className='button-container-back desktop'>
                <button onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
                    <span></span>
                    Volver
                </button>
            </div>

            <div className='title-prices-component' dangerouslySetInnerHTML={{ __html: props.data.title }} />
            <div dangerouslySetInnerHTML={{ __html: props.data.description }} />
            <div style={{marginBottom: '40px'}} dangerouslySetInnerHTML={{ __html: props.data.subtitle }} />
            <ValuesComponent data={props.prices} className='values-component'/>
            <ValuesComponentMobile data={props.prices} className='values-component-mobile'/>
            <div className='button-container'>
                <Link to={'/reservas'} className='bi-button-primary'>Quiero reservar</Link>
            </div>
            <div id='title-prices' dangerouslySetInnerHTML={{ __html: props.data.information.title }} />
            <div dangerouslySetInnerHTML={{ __html: props.data.information.description }} />
            <div className='targets'>
                {props.data.information.targets.map((target, index) => (
                    <div className='target' key={index}>
                        <div dangerouslySetInnerHTML={{ __html: target.icon }} />
                        <div dangerouslySetInnerHTML={{ __html: target.text }} />
                    </div>
                ))}
            </div>
        </>
    )
}
