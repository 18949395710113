import React, { useEffect, useState } from 'react'
import { priceToCLP, stringToHTML } from '../../utils/utils';

export const ValuesComponent = (props) => {
    const [data, setData] = useState([]);
    const [nameFacility, setNameFacility] = useState([]);

    useEffect(() => {
        if (Array.isArray(props.data)) {
            setData(props.data);
            props.data.map((item) => {
                setNameFacility(item.valores);
            })
        }
    }, [props.data])
    return (
        <div className='values-wrapper'>
            <div className='box-container'>
                <div className='box-head'>
                    <span></span>
                    {nameFacility.map((name, index) => (
                        <p className='title' key={index}>{name.instalacion}</p>
                    ))}
                </div>
                <div className='box-body'>
                    {data.map((price, index1) => (
                        <React.Fragment key={index1}>
                            <div className='tr' dangerouslySetInnerHTML={{ __html: stringToHTML(price.Tnombre) }} />
                            {price.valores.map((value, index) => (
                                <div className={`td ${price.now ? 'td-orange' : 'td'}`} key={index}>{priceToCLP(value.valor)}<span>x noche</span></div>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}
