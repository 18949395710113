import React, { useEffect, useState } from 'react'
import { ContactUsContentComponent } from '../../components/contact/ContactUsContentComponent'
import { useSelector } from 'react-redux'

export const ContactUsView = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const dataRedux = useSelector((state) => state.global);

  useEffect(() => {
    window.scrollTo(0, 0);
    setData(dataRedux.contact.contactUs)
    setLoading(false);
  }, [data, dataRedux])


  if (isLoading) { return <div className='loader'><span className='loading'></span></div> }

  return (
    <>
      <ContactUsContentComponent data={data} configuration={dataRedux.configuration} />
    </>
  )
}