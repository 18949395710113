import '../App.scss';
import { HomeContentComponent } from '../components/content/HomeContentComponent';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


function Home() {
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const dataRedux = useSelector((state) => state.global);

    useEffect(() => {
        window.scrollTo(0, 0);
        setData(dataRedux.home)
        setLoading(false);
    }, [data, dataRedux])


    if (isLoading) { return <div className='loader'><span className='loading'></span></div> }

    return (
        <div className="home-container">
            <HomeContentComponent data={data} />
        </div>
    );
}

export default Home;
