import { differenceInDays, parseISO, format } from 'date-fns';

export const extractOptions = (data) => {
    const optionsRegex = /<option\s+value='(\d+)'\s+max-guest='(\d+)'>\s*([^<]+)\s*<\/option>/g;
    const options = [];
    let match;

    while ((match = optionsRegex.exec(data)) !== null) {
        const [, value, maxGuest, text] = match;
        options.push({
            value,
            maxGuest,
            text: text.trim(),
        });
    }

    return options;
};

export const getDaysDifference = (fecha1, fecha2) => {
    const date1 = parseISO(fecha1);
    const date2 = parseISO(fecha2);
    const diasDiferencia = differenceInDays(date2, date1);
    return diasDiferencia;
};

export const formatDateToDDMMYYYY = (fechaString) => {
    console.log('fechaString', fechaString);
    const fecha = parseISO(fechaString);
    const fechaFormateada = format(fecha, 'dd-MM-yyyy');
    return fechaFormateada;
};

export const calculatePrice = (precios, tipoInstalacion, dias) => {
    console.log("precios", precios);
    console.log("tipoInstalacion", tipoInstalacion);
    console.log("dias", dias);
    const prices = [
        {
            "id": 6,
            "nombre": "Cabañas (5 pers )",
            "cnt": 58000.00
        },
        {
            "id": 7,
            "nombre": "Habitac. ( 2 pers. )",
            "cnt": 38000.00
        },
        {
            "id": 10,
            "nombre": "Habitac. ( 3 pers. )",
            "cnt": 40000.00
        },
        {
            "id": 11,
            "nombre": "Deptos 1er Piso (6 pers.)",
            "cnt": 68000.00
        },
        {
            "id": 12,
            "nombre": "Deptos 2do Piso (6 pers.)",
            "cnt": 68000.00
        }
    ];

    const instalacion = prices.find(item => item.id == tipoInstalacion);
    if (instalacion) {
        const precioTotal = instalacion.cnt * Number(dias);
        return precioTotal;
    } else {
        return "Instalación no encontrada";
    }
};

export const priceToCLP = (precio) => {
    const precioFormateado = Number(precio).toLocaleString('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    const precioFinal = precioFormateado.replace('CLP', '$');
    return precioFinal;
};

export const stringToHTML = (inputString) => {
    const matches = inputString.match(/(TEMPORADA|Precios rack|Habitac.)([^()]+(\([^)]+\))|[^()]+)/i);

    if (matches && matches.length >= 3) {
        const textoInicio = matches[1].trim();
        const restoCadena = matches[2].trim();

        return `<p>${textoInicio}</p><strong>${restoCadena}</strong></p>`;
    } else {
        return inputString;
    }
}

export const nameAndLastnameSeparate = (nombreCompleto) => {
  const palabras = nombreCompleto.split(' ');
  let nombres, apellidos;
  if (palabras.length === 1) {
    nombres = palabras[0];
    apellidos = '';
  } else if (palabras.length === 2) {
    nombres = palabras[0];
    apellidos = palabras.slice(1).join(' ');
  } else if (palabras.length >= 3) {
    nombres = palabras.slice(0, 2).join(' ');
    apellidos = palabras.slice(2).join(' ');
  }
  return {
    nombres: nombres,
    apellidos: apellidos
  };
}