import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    height: 0,
    withButtonSecondary: false
}

export const buttonReserve = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setHeight: (state, action) => {
            state.height = action.payload;
        },
        setWithButtonSecondary: (state, action) => {
            state.withButtonSecondary = action.payload;
        }
    }
})

export const {
    setHeight,
    setWithButtonSecondary
} = buttonReserve.actions;

export default buttonReserve.reducer;