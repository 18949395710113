import React, { useEffect, useState } from 'react'
import { AvailabilityComponent } from '../../components/availability/AvailabilityComponent'
import { getDataFacilities } from '../../services/index'
import { ReserveContentComponent } from '../../components/facilities/ReserveContentComponent'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'

export const FacilitiesReserveView = () => {
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [facilities, setFacilities] = useState({})
    const dataRedux = useSelector((state) => state.global);
    const [globalData, setGlobalData] = useState({})
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        const getFacilities = async () => {
            const response = await getDataFacilities();
            if (response) {
                setGlobalData(dataRedux.global)
                setData(dataRedux.facilities)
                setFacilities(response)
                setLoading(false);
            } else {
                navigate('/error');
            }
        }
        getFacilities();
    }, [])

    if (isLoading) { return <div className='loader'><span className='loading'></span></div> }

    return (
        <>
            <div className='stepper-mobile stepper2'>
                <div className='stepper-wrapper-mobile'>
                    <div className='stepper-fixed'>
                        <p className='title-reserve-component'>Reservas</p>
                        <div className='stepper'>
                            <div className='bar bar1'></div>
                            <div className='bar bar2'></div>
                            <div className='bar bar3'></div>
                        </div>
                    </div>
                    <div className='text'>
                        <p className='text1'>Selecciona la instalación</p>
                        <p className='text2'>Completa los datos</p>
                        <p className='text3'>¡Está lista tu reserva!</p>
                    </div>
                </div>
            </div>
            <div className='facilities-reserve-container'>
                <AvailabilityComponent />
                <ReserveContentComponent data={data} globalData={globalData} facilities={facilities} />
            </div>
        </>
    )
}
