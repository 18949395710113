import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const SliderItem = ({ src }) => (
    <div className="item">
        <img src={src} alt="" />
    </div>
);

const SliderComponent = () => {
    const [active, setActive] = useState(0);
    const [images, setImages] = useState([]);
    const dataRedux = useSelector((state) => state.global);
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 800);

    useEffect(() => {
        if (dataRedux.configuration && dataRedux.configuration.slider) {
            setImages(dataRedux.configuration.slider);
        }
    }, [dataRedux.configuration.slider]);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setActive((prevActive) => (prevActive === images.length - 1 ? 0 : prevActive + 1));
        }, 5000);

        return () => clearInterval(interval);
    }, [images]);

    const handlePrevClick = () => {
        setActive((prevActive) => (prevActive === 0 ? images.length - 1 : prevActive - 1));
    };

    const handleNextClick = () => {
        setActive((prevActive) => (prevActive === images.length - 1 ? 0 : prevActive + 1));
    };

    const renderDots = () =>
        images.map((_, index) => (
            <li key={index} className={index === active ? 'active' : ''} onClick={() => setActive(index)}></li>
        ));

    const style = {
        left: isWideScreen ? -active * 100 + '%' : -active * 100 + 'vw',
    };

    return (
        <div className="slider-container">
            <div id="slider" className="slider">
                <div className="list" style={style}>
                    {images.map((src, index) => (
                        <SliderItem key={index} src={src} />
                    ))}
                </div>

                <div className="buttons">
                    <button id="prev" onClick={handlePrevClick}>
                        {'<'}
                    </button>
                    <button id="next" onClick={handleNextClick}>
                        {'>'}
                    </button>
                </div>

                <div className="dots">{renderDots()}</div>
            </div>
        </div>
    );
};

export default SliderComponent;
