import React, { useEffect, useState } from 'react'
import { AvailabilityComponent } from '../../components/availability/AvailabilityComponent'
import { getDataFacility } from '../../services/index'
import { SelectContentComponent } from '../../components/facilities/SelectContentComponent'
import { useParams } from 'react-router-dom'

export const FacilitiesSelectView = () => {
    const idTipoInstalacion = useParams().id;
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            console.log("idTipoInstalacion", idTipoInstalacion);
            const response = await getDataFacility(idTipoInstalacion);
            setData(response);
            console.log("data facilities", response);
            setLoading(false);
        }
        fetchData();
    }, [])


    if (loading) {
        return '.....loading'
    }
    return (
        <div className='facilities-select-container'>
            <AvailabilityComponent />
            <SelectContentComponent data={data} />
        </div>
    )
}
