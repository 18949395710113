import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ImagesGalleryComponent } from '../globals/ImagesGalleryComponent';

export const ServicesContentComponent = (props) => {
    const [selectedImages, setSelectedImages] = useState({});
    var index = 0;
    const navigate = useNavigate();
    const [showGallery, setShowGallery] = useState(false);

    const handleVolverAtras = () => {
        navigate(-1);
    };

    const handleImageClick = (sectionIndex, selectedImage) => {
        setSelectedImages((prevSelectedImages) => ({
            ...prevSelectedImages,
            [sectionIndex]: selectedImage,
        }));
    };

    const handleVerMasClick = () => {
        setShowGallery(true);
    };

    const handleCloseGallery = () => {
        setShowGallery(false);
    };

    return (
        <div className='content-component'>

            <div className='mobile-services'>
                <div className='button-container-back mobile'>
                    <button onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
                        <span class="button-icon-services"></span>
                    </button>
                </div>
                <p className='title-content-component'>Servicios</p>
            </div>

            <div className='button-container-back desktop'>
                <button onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
                    <span></span>
                    Volver
                </button>
            </div>

            <div className='title-services' dangerouslySetInnerHTML={{ __html: props.data.title }} />
            <div dangerouslySetInnerHTML={{ __html: props.data.description }} />

            <div className='button-container'>
                <Link to={'/reservas'} className='bi-button-primary'>Quiero reservar</Link>
            </div>

            <div className='categories-content'>
                <div className='galery-container'>
                    <div className='galery'>
                        <img src={selectedImages[index] || (props.data.imageGalery && props.data.imageGalery[0])} />
                        <button className='bi-button-secondary' onClick={handleVerMasClick}>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_544_1056)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.99782 1.50001L3 1.5L18.0022 1.5C18.7965 1.50231 19.5577 1.81889 20.1194 2.3806C20.6811 2.9423 20.9977 3.70346 21 4.49783L21 4.5L21 5.25H19.5V4.50102C19.4985 4.10339 19.3399 3.72245 19.0587 3.44126C18.7775 3.16002 18.3965 3.00142 17.9988 3H3.0012C2.60351 3.00142 2.22249 3.16002 1.94125 3.44126C1.66002 3.72249 1.50141 4.10351 1.5 4.5012V16.4988C1.50141 16.8965 1.66002 17.2775 1.94125 17.5588C2.2225 17.84 2.60356 17.9986 3.00128 18H3.75V19.5H3L2.99782 19.5C2.20346 19.4977 1.44229 19.1811 0.880592 18.6194C0.318891 18.0577 0.00230998 17.2965 3.15905e-06 16.5022L0 16.5V4.49783C0.00230682 3.70346 0.318891 2.9423 0.880592 2.3806C1.44229 1.81889 2.20346 1.50231 2.99782 1.50001Z" fill="black" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.65578 6.75C5.87939 6.75 5.25 7.37939 5.25 8.15578V19.5942C5.25 20.3706 5.87939 21 6.65578 21H21.0942C21.8706 21 22.5 20.3706 22.5 19.5942V8.15578C22.5 7.37939 21.8706 6.75 21.0942 6.75H6.65578ZM3.75 8.15578C3.75 6.55096 5.05096 5.25 6.65578 5.25H21.0942C22.699 5.25 24 6.55096 24 8.15578V19.5942C24 21.199 22.699 22.5 21.0942 22.5H6.65578C5.05096 22.5 3.75 21.199 3.75 19.5942V8.15578Z" fill="black" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.4804 9.61328C17.093 9.61328 16.7881 9.9237 16.7881 10.2953C16.7881 10.6669 17.093 10.9773 17.4804 10.9773C17.8679 10.9773 18.1728 10.6669 18.1728 10.2953C18.1728 9.9237 17.8679 9.61328 17.4804 9.61328ZM15.2881 10.2953C15.2881 9.08514 16.2747 8.11328 17.4804 8.11328C18.6861 8.11328 19.6728 9.08515 19.6728 10.2953C19.6728 11.5055 18.6861 12.4773 17.4804 12.4773C16.2747 12.4773 15.2881 11.5055 15.2881 10.2953Z" fill="black" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.48028 12.7755C9.89831 12.4073 10.4404 12.2115 10.9972 12.2275C11.554 12.2434 12.084 12.47 12.4803 12.8615L12.4811 12.8624L16.4033 16.7504L17.4632 15.6983L17.4649 15.6965C17.8528 15.314 18.3685 15.0888 18.9127 15.0642C19.4568 15.0397 19.9908 15.2176 20.4114 15.5636L20.4131 15.565L23.7281 18.3077C24.0472 18.5717 24.0919 19.0445 23.8279 19.3636C23.5638 19.6828 23.0911 19.7274 22.7719 19.4634L19.4586 16.7221C19.4584 16.7219 19.4588 16.7223 19.4586 16.7221C19.3244 16.612 19.1536 16.5549 18.9802 16.5627C18.8069 16.5705 18.6426 16.6422 18.5189 16.7638C18.5187 16.7641 18.5191 16.7636 18.5189 16.7638L12.9611 22.2814C12.6671 22.5732 12.1922 22.5715 11.9004 22.2775C11.6086 21.9836 11.6103 21.5087 11.9043 21.2169L15.3388 17.8072L11.426 13.9286C11.4259 13.9284 11.4261 13.9287 11.426 13.9286C11.3 13.8042 11.1312 13.7319 10.9542 13.7269C10.7771 13.7218 10.6047 13.784 10.4717 13.9011C10.4717 13.9011 10.4718 13.9011 10.4717 13.9011L4.99621 18.7321C4.68561 19.0062 4.21166 18.9765 3.93762 18.6659C3.66357 18.3553 3.69321 17.8814 4.00381 17.6073L9.48028 12.7755Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_544_1056">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </span>
                            Ver más
                        </button>
                    </div>
                    {showGallery && (
                        <ImagesGalleryComponent
                            images={props.data.imageGalery}
                            onClose={handleCloseGallery}
                        />
                    )}
                    <div className='images-mini'>
                        {props.data.imageGalery && props.data.imageGalery.map((image, imageIndex) => (
                            <div
                                className='image'
                                key={imageIndex}
                                onClick={() => handleImageClick(index, image)}
                            >
                                <img src={image} />
                            </div>
                        ))}
                    </div>
                    <div className='map' dangerouslySetInnerHTML={{ __html: props.configuration.mapa }} />
                </div>
                <div className='categories categories-desktop'>
                    {props.data.categories && props.data.categories.map((category, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'start', marginBottom: '40px' }}>
                            <div className='icon'>
                                <span dangerouslySetInnerHTML={{ __html: category.icon }} />
                            </div>
                            <div className='category-text'>
                                <p dangerouslySetInnerHTML={{ __html: category.title }} />
                                {category.characteristics.map((characteristic, index) => (
                                    <div className='characteristics' key={index}>
                                        <span dangerouslySetInnerHTML={{ __html: characteristic.icon }} />
                                        <p dangerouslySetInnerHTML={{ __html: characteristic.text }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='categories categories-mobile'>
                    {props.data.categories && props.data.categories.map((category, index) => (
                        <div key={index} className='category-container'>
                            <div className='icon-and-title'>
                                <div className='icon'>
                                    <span dangerouslySetInnerHTML={{ __html: category.icon }} />
                                </div>
                                <p className='category-title' dangerouslySetInnerHTML={{ __html: category.title }} />
                            </div>
                            <div className='characteristics-container'>
                                {category.characteristics.map((characteristic, index) => (
                                    <div className='characteristics' key={index}>
                                        <span dangerouslySetInnerHTML={{ __html: characteristic.icon }} />
                                        <p dangerouslySetInnerHTML={{ __html: characteristic.text }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='button-container'>
                <Link to={'/reservas'} className='bi-button-primary'>Quiero reservar</Link>
            </div>
        </div>
    )
}