import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

export const MessageSuccessComponent = () => {
  const navigate = useNavigate();

  const handleVolverAtras = () => {
    navigate(-1);
  };

  return (
    <>
      <button style={{ marginTop: '24px' }} onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
          <span class="button-icon-message"></span>
          <p class="button-text">Volver</p>
      </button>
      <div className='divider' id='divider-message'></div>
      <div className='message-container'>
        <div>
          <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.8912 15.2773H85.9479C90.8585 15.2773 94.8391 19.3067 94.8391 24.2773V78.2773C94.8391 83.248 90.8585 87.2773 85.9479 87.2773H8.8912C3.98068 87.2773 3.8147e-05 83.248 3.8147e-05 78.2773V24.2773C3.8147e-05 19.3067 3.98068 15.2773 8.8912 15.2773ZM8.8912 24.2773V31.9283C13.0445 35.3518 19.6658 40.6753 33.8211 51.8952C36.9406 54.379 43.12 60.3462 47.4196 60.2766C51.7185 60.3469 57.8998 54.378 61.0181 51.8952C75.1711 40.677 81.7941 35.3524 85.9479 31.9283V24.2773H8.8912ZM85.9479 78.2773V43.477C81.7035 46.899 75.6844 51.7011 66.51 58.9731C62.4614 62.199 55.3712 69.3205 47.4196 69.2772C39.507 69.3205 32.5067 62.3023 28.3308 58.9746C19.1566 51.7028 13.1358 46.8994 8.8912 43.4772V78.2773H85.9479Z" fill="#9B9B9B" />
            <path d="M117 77C117 91.3594 105.359 103 91 103C76.6406 103 65 91.3594 65 77C65 62.6406 76.6406 51 91 51C105.359 51 117 62.6406 117 77Z" fill="white" />
            <path d="M91 51C97.8956 51 104.509 53.7393 109.385 58.6152C114.261 63.4912 117 70.1044 117 77C117 83.8956 114.261 90.5088 109.385 95.3848C104.509 100.261 97.8956 103 91 103C84.1044 103 77.4912 100.261 72.6152 95.3848C67.7393 90.5088 65 83.8956 65 77C65 70.1044 67.7393 63.4912 72.6152 58.6152C77.4912 53.7393 84.1044 51 91 51ZM87.7611 82.1294L81.9854 76.35C81.7784 76.1429 81.5326 75.9787 81.262 75.8666C80.9915 75.7546 80.7015 75.6969 80.4087 75.6969C80.1159 75.6969 79.8259 75.7546 79.5554 75.8666C79.2849 75.9787 79.0391 76.1429 78.832 76.35C78.4138 76.7682 78.1789 77.3353 78.1789 77.9267C78.1789 78.5181 78.4138 79.0853 78.832 79.5034L86.1863 86.8577C86.3928 87.0658 86.6384 87.231 86.909 87.3437C87.1796 87.4564 87.4699 87.5144 87.763 87.5144C88.0561 87.5144 88.3464 87.4564 88.617 87.3437C88.8876 87.231 89.1332 87.0658 89.3397 86.8577L104.568 71.6254C104.778 71.4192 104.945 71.1735 105.059 70.9025C105.174 70.6315 105.233 70.3405 105.235 70.0463C105.236 69.7521 105.179 69.4606 105.067 69.1885C104.956 68.9164 104.791 68.6692 104.583 68.461C104.375 68.2529 104.128 68.088 103.856 67.9758C103.584 67.8636 103.293 67.8063 102.999 67.8074C102.704 67.8084 102.413 67.8676 102.142 67.9817C101.871 68.0958 101.625 68.2624 101.419 68.472L87.7611 82.1294Z" fill="#14CC69" />
          </svg>
        </div>

        <p className='title'>¡Tu mensaje ha sido enviado con éxito!</p>
        <p className='subtitle'>Gracias por contactarte con nosotros, pronto te responderemos.</p>

        <div className='buttons'>
          <Link className='bi-button-primary' style={{ padding: '8px 64px' }} to={'/'}>Ir al home</Link>
        </div>
      </div>
    </>
  )
}