import React, { useEffect, useState } from 'react'
import { AvailabilityComponent } from '../../components/availability/AvailabilityComponent'
import { StoryContentComponent } from '../../components/contact/StoryContentComponent'
import { useSelector } from 'react-redux'
import { ButtonReserveComponent } from '../../components/globals/ButtonReserveComponent'
import { Link, useNavigate } from 'react-router-dom';

export const StoryView = () => {
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const dataRedux = useSelector((state) => state.global);

    useEffect(() => {
        window.scrollTo(0, 0);
        setData(dataRedux.contact.story)
        console.log("dataRedux contact", dataRedux);
        setLoading(false);
    }, [data, dataRedux])

    const navigate = useNavigate();

    const handleVolverAtras = () => {
        navigate(-1);
    };


    if (isLoading) { return <div className='loader'><span className='loading'></span></div> }

    return (
        <>
        <div className='mobile-services'>
            <div className='button-container-back mobile'>
                <button onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
                <span class="button-icon-story"></span>
                </button>
            </div>
            <p className='title-content mobile'>Nosotros</p>
        </div>

        <div className='story-container'>
            <AvailabilityComponent />
            <StoryContentComponent data={data} />
        </div>
        < ButtonReserveComponent withButtonSecondary={true} />
        </>
    )
}
