import React, { useEffect, useState } from 'react'
import { BookingsConfirmComponent } from '../../components/bookings/BookingsConfirmComponent'
import { useSelector } from 'react-redux';
export const BookingsConfirmView = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const dataRedux = useSelector((state) => state.global);

  useEffect(() => {
      window.scrollTo(0, 0);
      setData(dataRedux.facilities)
      setLoading(false);
  }, [data, dataRedux])


  if (isLoading) { return <div className='loader'><span className='loading'></span></div> }

  return (
    <div className='bookings-confirm-container'>
      <BookingsConfirmComponent data={data} />
    </div>
  )
}