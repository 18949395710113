import React from 'react'
import { Link } from 'react-router-dom'
import SliderComponent from '../slider/SliderComponent'
import { FormAvailabilityComponent } from '../forms/FormAvailabilityComponent'
import { ButtonReserveComponent } from '../globals/ButtonReserveComponent'

export const HomeContentComponent = (props) => {
    return (
        <>
            <div className='bar-top'></div>
            <div className='home-content'>
                <div className='info'>
                    <div className='description'>
                        <div dangerouslySetInnerHTML={{ __html: props.data.title.value }} />
                        <div dangerouslySetInnerHTML={{ __html: props.data.subtitle }} />
                    </div>
                    <div className='form-content'>
                        <p className='form-title'>Reserva online</p>
                        <FormAvailabilityComponent />
                    </div>
                    <div className='targets'>
                        {props.data.targets.map((target, index) => (
                            <div className='target' key={index}>
                                <span className='icon' style={{ background: target.backgroundIcon }} dangerouslySetInnerHTML={{ __html: target.icon }}></span>
                                <div dangerouslySetInnerHTML={{ __html: target.title }} />
                                <div dangerouslySetInnerHTML={{ __html: target.description }} />
                                <Link to={target.url} className='bi-button-hyperlink'>{target.textLink}</Link>
                            </div>
                        ))}
                    </div>
                </div>
                <SliderComponent />
            </div>
            < ButtonReserveComponent withButtonSecondary={false} />
        </>
    )
}