import React, { useState, useEffect, useRef } from 'react';

export const SelectHeaderComponent = ({ options, onSelectChange, isHeader }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const selectRef = useRef(null);
  const optionsContainerRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedValue(option.value);
    setSelectedLabel(option.option);
    setIsOpen(false);
    if (onSelectChange) {
      onSelectChange(option.value);
    }
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className='selectbox' ref={selectRef}>
      <p>{options[0].title}</p>
      <div className="selectbox2">
        <div className="select" onClick={toggleDropdown}>
          <p>{isHeader ? options[0].label : selectedLabel || options[0].label}</p>
          <span className={isOpen ? 'arrow-up' : 'arrow-down'}></span>
        </div>
        {isOpen && (
          <ul className="options" ref={optionsContainerRef} onMouseLeave={handleMouseLeave}>
            {options.map((option) => (
              <li className='option' key={option.value} onClick={() => handleOptionClick(option)}>
                <p>{option.option}</p>
                <span className='arrow-right'></span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};