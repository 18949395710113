import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { SelectHeaderComponent } from '../inputs/SelectHeaderComponent';

export const HeaderComponent = () => {
    const [route, setRuta] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [inContact, setInContact] = useState(false);
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    };

    const closeMenuMobile = () => {
        setMobileMenuVisible(false);
    };

    useEffect(() => {
        if (mobileMenuVisible) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [mobileMenuVisible]);

    const options = [
        { title: '', label: 'Conózcanos', option: 'Contáctenos', value: '/contacto' },
        { title: '', label: 'Conózcanos', option: 'Nosotros', value: '/contacto/nosotros' }
    ];

    const menuIconClosed = '/assets/images/menu-mb.svg'; 
    const menuIconOpen = '/assets/images/menu-mb-x.svg';


    const handleSelectChange = (selectedDate) => {
        console.log("selectedDate", selectedDate);
        if (selectedDate) {
            setRuta(selectedDate);
        }
        closeMenuMobile();
    }

    useEffect(() => {
        navigate(route);
    }, [route]);

    useEffect(() => {
        const unlisten = () => {
            if (location.pathname.includes("contacto")) {
                setInContact(true);
            } else {
                setInContact(false);
            }
        };
        unlisten();
    }, [location]);

    return (
        <>
            <header>
                <div className='logo-container'>
                    <NavLink to="/inicio" className='logo-text'>
                        <span className='logo'></span>
                    </NavLink>
                    <button className="menu-toggle" onClick={toggleMobileMenu}>
                        <img src={ mobileMenuVisible ? menuIconOpen : menuIconClosed } alt="Menu" />
                    </button>
                </div>
                
                <nav className={mobileMenuVisible ? '' : 'menu-hidden'}>
                    <ul>
                        
                        <li>
                            <NavLink to="/reservas"onClick={closeMenuMobile} className={({ isActive }) => isActive ? 'bi-button-secondary active' : 'bi-button-secondary'}>Reservas</NavLink>
                            <NavLink to="/reservas" className='arrow-right' onClick={closeMenuMobile}></NavLink>
                        </li>

                        <li>
                            <NavLink to="/servicios" onClick={closeMenuMobile} className={({ isActive }) => isActive ? 'bi-button-secondary active' : 'bi-button-secondary'}>Servicios</NavLink>
                            <NavLink to="/servicios" className='arrow-right' onClick={closeMenuMobile}></NavLink>
                        </li>

                        <li>
                            <NavLink to="/instalaciones" onClick={closeMenuMobile} className={({ isActive }) => isActive ? 'bi-button-secondary active' : 'bi-button-secondary'}>Instalaciones</NavLink>
                            <NavLink to="/instalaciones" className='arrow-right' onClick={closeMenuMobile}></NavLink>
                        </li>

                        <li>
                            <NavLink to="/valores" onClick={closeMenuMobile} className={({ isActive }) => isActive ? 'bi-button-secondary active' : 'bi-button-secondary'}>Valores</NavLink>
                            <NavLink to="/valores" className='arrow-right' onClick={closeMenuMobile}></NavLink>
                        </li>

                        <li className='li-contacto'>
                            <div id='select-contacto' style={{ width: '195px' }} className={`${inContact ? 'bi-button-secondary active' : 'bi-button-secondary'}`}>
                                <SelectHeaderComponent options={options} onSelectChange={handleSelectChange} isHeader={true} />
                            </div>
                        </li>
                        {/* <li>
                            <button className='bi-button-search'>
                                <span className='search-icon'></span>
                            </button>
                        </li> */}
                    </ul>
                </nav>
            </header>
        </>
    )
}
