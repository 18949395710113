import React from 'react'
import { useSelector } from 'react-redux';

export const FooterComponent = () => {
  const dataReduxGlobal = useSelector((state) => state.global.configuration);
  const dataRedux = useSelector((state) => state.buttonReserve);
  return (

    <footer>
      <div className='footer-container' style={{ marginBottom: `${dataRedux.height}px` }}>
        <div className='logo-container'>
          <span className='logo'></span>
        </div>
        <div className='item locations'>
          <p className='title'>UBICACIÓN</p>
          <p>Cabañas Bahía Isidoro s/n</p>
          <p>El Quisco</p>
          <p>Región de Valparaíso.</p>
          <p>¿Cómo llegar?</p>
        </div>
        <br></br>
        <div className='item links'>
          <p className='title'>ENLACES</p>
          <p>Convenios con empresas</p>
          <p>Servicios y entretenimiento</p>
          <p>Lista de promociones</p>
          <p>Explore el litoral central</p>
        </div>
        <br></br>
        <div className='item contact'>
          <p className='title'>CONTÁCTANOS</p>
          <p>De lunes a domingo </p>
          <p>9:00 am a 8:00 pm</p>
          <p>{dataReduxGlobal.whatsapp}</p>
        </div>
      </div>
    </footer>

  )
}
