import React, { useState, useEffect } from 'react';
import { SingleSelectComponent } from '../../components/inputs/SingleSelectComponent';
import { getCheckAvailability } from '../../services';
import { extractOptions } from '../../utils/utils';
import { DatePickerComponent } from '../../components/inputs/DatePickerComponent';
import { useNavigate } from 'react-router-dom';
import { addDays, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useSelector } from 'react-redux';

export const AvailabilityComponent = () => {
    const navigate = useNavigate();
    const [dataInstalaciones, setDataInstalaciones] = useState([]);
    const [dataInstalacionesOptions, setDataInstalacionesOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [minSalidaDate, setMinSalidaDate] = useState(new Date());
    const [minEntradaDate, setMinEntradaDate] = useState(new Date());
    const [isSalidaEnabled, setIsSalidaEnabled] = useState(false);
    const [isNumTipoEnabled, setIsNumTipoEnabled] = useState(false);
    const [isGuestEnabled, setIsGuestEnabled] = useState(false);
    const [maxGuest, setMaxGuest] = useState(0);
    const [guestExceeded, setGuestExceeded] = useState(false);
    const [childrenExceeded, setChildrenExceeded] = useState(false);
    const [optionsTipo, setOptionsTipo] = useState([
        { title: 'Tipo de instalación', label: 'Todas', option: 'Selecciona', value: '1' }
    ]);
    const [optionsNumTipo, setOptionsNumTipo] = useState([
        { title: 'Nº Instalaciones', label: 'Selecciona', option: '0', value: '0' },
    ]);
    const [tipoValue, setTipoValue] = useState('');
    const [numTipoValue, setNumTipoValue] = useState('0');
    const [adultoValue, setAdultoValue] = useState('0');
    const [ninosValue, setNinosValue] = useState('0');
    const [entradaValue, setEntradaValue] = useState('');
    const [salidaValue, setSalidaValue] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [llegadaSelected, setLlegadaSelected] = useState(false);
    const [tipoFacilitySelected, setTipoFacilitySelected] = useState(false);
    const dataRedux = useSelector((state) => state.global);

    useEffect(() => {
        window.scrollTo(0, 0);
        setDataInstalaciones(dataRedux.facilitiesOptions);
    }, [])

    useEffect(() => {
        let optionsArray = [];
        if (dataInstalaciones.length > 0) {
            dataInstalaciones.forEach((element) => {
                const options = extractOptions(element.title);
                optionsArray.push(...options);
            });
            setDataInstalacionesOptions(optionsArray);
        }
    }, [dataInstalaciones]);


    useEffect(() => {
        if (dataInstalacionesOptions.length > 0) {
            setOptionsTipo(() => {
                const newOptionsTipo = dataInstalacionesOptions.map((element) => ({
                    title: 'Tipo de instalación',
                    label: 'Todas',
                    option: element.text,
                    value: element.value
                }));
                return [...newOptionsTipo];
            });
        }
    }, [dataInstalacionesOptions]);

    const availableCabinList = async (installationTypeId) => {
        let cabinOptions = null;
        dataInstalaciones.map((options) => {
            if (options.id == installationTypeId) {
                cabinOptions = extractOptions(options.data);
            }
        })
        return cabinOptions;
    };

    const handleSelectChangeOfType = async (selectedValue) => {
        if (selectedValue) {
            const updatedOptionsNumTipo = await availableCabinList(selectedValue);
            if (updatedOptionsNumTipo) {
                setOptionsNumTipo(() => {
                    const newOptionsTipo = updatedOptionsNumTipo.map((element) => ({
                        title: 'Nº Instalaciones',
                        label: 'Selecciona',
                        option: element.text,
                        value: element.value
                    }));
                    return newOptionsTipo;
                });
            }
            setTipoValue(selectedValue);
            setIsNumTipoEnabled(true);
            setTipoFacilitySelected(true);
            setNumTipoValue(null);
        }
    };

    const handleSelectChangeOfNumType = async (selectedValue) => {
        if (selectedValue) {
            setNumTipoValue(selectedValue);
        }
        dataInstalacionesOptions.map((element) => {
            if (element.value == tipoValue) {
                const maxGuest = parseInt(element.maxGuest) * parseInt(selectedValue);
                setMaxGuest(maxGuest);
                setIsGuestEnabled(true);
            }
        })
        setTipoFacilitySelected(false);
    };
    const handleSelectChangeOfAdultos = async (selectedValue) => {
        if (selectedValue) {
            setAdultoValue(selectedValue.target.value);
        }
    };
    const handleSelectChangeOfNinos = async (selectedValue) => {
        if (selectedValue) {
            setNinosValue(selectedValue.target.value);
        }
    };

    useEffect(() => {
        calculateGuest(adultoValue, ninosValue);
    }, [adultoValue, ninosValue, numTipoValue, tipoValue]);

    const calculateGuest = (adultos, ninos) => {
        const guests = parseInt(adultos) + parseInt(ninos);
        if (guests > maxGuest) {
            setGuestExceeded(true);
        } else {
            setGuestExceeded(false);
        }

        if (parseInt(adultos) < numTipoValue && parseInt(adultoValue) !== 0) {
            setChildrenExceeded(true);
        } else {
            setChildrenExceeded(false);
        }
    }

    useEffect(() => {
        setIsButtonDisabled(!tipoValue || !numTipoValue || adultoValue == 0 || !entradaValue || !salidaValue || guestExceeded || childrenExceeded);
    }, [tipoValue, numTipoValue, adultoValue, entradaValue, salidaValue, guestExceeded, childrenExceeded]);

    const handleSelectChangeOfEntrada = (selectedDate) => {
        if (selectedDate) {
            setEntradaValue(selectedDate);
        }

        const entradaDate = parse(selectedDate, 'yyyy-MM-dd', new Date(), { timeZone: 'America/Santiago' });
        const minDateForSalida = addDays(utcToZonedTime(entradaDate, 'America/Santiago'), 1);
        setMinSalidaDate(minDateForSalida);

        setSalidaValue('');
        setIsSalidaEnabled(true);
        setLlegadaSelected(true);
    }

    const handleSelectChangeOfSalida = (selectedDate) => {
        if (selectedDate) {
            setSalidaValue(selectedDate);
        }

        setLlegadaSelected(false);
    }

    const optionsEntrada = { title: 'Llegada', label: 'Llegada' };
    const optionsSalida = { title: 'Salida', label: 'Salida' };

    const dataToReserve = () => {
        sessionStorage.setItem('dataReserve', JSON.stringify({
            tipo: tipoValue,
            numTipo: numTipoValue,
            adultos: adultoValue,
            ninos: ninosValue,
            entrada: entradaValue,
            salida: salidaValue
        }));
    };

    // const goToReserveFacility = async () => {
    //     let url = '';
    //     const response = await getCheckAvailability({
    //         tipo: tipoValue,
    //         instalaciones: numTipoValue,
    //         adultos: adultoValue,
    //         ninos: ninosValue,
    //         from: entradaValue,
    //         to: salidaValue
    //     })
    //     if(response.data.status === 1) {
    //         dataToReserve();
    //         url = `/instalaciones/reservar/${tipoValue}`;
    //         navigate(url)
    //     } else {
    //         url = '/error';
    //     }
    //     navigate(url)
    // }

    const goToReserveFacility = async () => {
        const response = await getCheckAvailability({
            tipo: tipoValue,
            instalaciones: numTipoValue,
            adultos: adultoValue,
            ninos: ninosValue,
            from: entradaValue,
            to: salidaValue
        });
        if (response.data.status === 1) {
            dataToReserve();
            navigate('/instalaciones/reservar');
        } else {
            navigate('/error/instalaciones');
        }
    };

    return (
        <div className='availability-wrapper'>
            <div className='availability'>
                <div className='input-wrapper'>
                    <DatePickerComponent options={optionsEntrada} onSelectDate={handleSelectChangeOfEntrada} minDate={minEntradaDate} />
                </div>
                <div className='input-wrapper'>
                    <DatePickerComponent options={optionsSalida} onSelectDate={handleSelectChangeOfSalida} minDate={minSalidaDate} isDisabled={!isSalidaEnabled} llegadaSelected={llegadaSelected} />
                </div>
                <div className='input-wrapper' id='input-tipo-facility' style={{ minWidth: '223px' }}>
                    <SingleSelectComponent options={optionsTipo} onSelectChange={handleSelectChangeOfType} />
                </div>
                <div className={`input-wrapper ${!isNumTipoEnabled ? 'selectDisabled' : ''}`} id='input-numtipo-facility' style={{ width: '137px' }}>
                    <SingleSelectComponent options={optionsNumTipo} onSelectChange={handleSelectChangeOfNumType} isDisabled={!isNumTipoEnabled} tipoFacilitySelected={tipoFacilitySelected} />
                </div>
                <div className='input-wrapper'>
                    <label htmlFor="adultos">Adultos</label>
                    <input style={{ width: '75px' }} type="number" id="adultos" name="adultos" placeholder='0' min={0} onChange={e => handleSelectChangeOfAdultos(e)} disabled={!isGuestEnabled} />
                </div>
                <div className='input-wrapper'>
                    <label htmlFor="ninos">Niños</label>
                    <input style={{ width: '75px' }} type="number" id="ninos" name="ninos" placeholder='0' min={0} onChange={e => handleSelectChangeOfNinos(e)} disabled={!isGuestEnabled} />
                </div>

                <div className='vertical-divider' style={{ height: '77px' }}></div>

                <button className='bi-button-primary' id='button-reserve-facility' disabled={isButtonDisabled} onClick={goToReserveFacility}>Ver disponibilidad</button>
            </div>
            {guestExceeded && !childrenExceeded &&
                <div className='alert alert-warning' style={{ marginTop: '36px' }}>
                    <span className='symbol' style={{ width: '32px' }}></span>
                    <div className='text'>
                        <p>Has superado la cantidad máxima de personas para visualizar el resultado según el tipo de instalación seleccionado. Por favor revisa los datos ingresados.</p>
                    </div>
                </div>
            }
            {childrenExceeded && !guestExceeded &&
                <div className='alert alert-warning' style={{ marginTop: '36px' }}>
                    <span className='symbol' style={{ width: '32px' }}></span>
                    <div className='text'>
                        <p>Debe haber al menos un adulto por instalación. Por favor revisa los datos ingresados.</p>
                    </div>
                </div>
            }
            {guestExceeded && childrenExceeded &&
                <div className='alert alert-warning' style={{ marginTop: '36px' }}>
                    <span className='symbol' style={{ width: '32px' }}></span>
                    <div className='text'>
                        <p>Debe haber al menos un adulto por instalación. Por favor revisa los datos ingresados.</p>
                        <p>Has superado la cantidad máxima de personas para visualizar el resultado según el tipo de instalación seleccionado. Por favor revisa los datos ingresados.</p>
                    </div>
                </div>
            }

        </div>
    );
};