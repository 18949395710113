import React, { useEffect, useState } from 'react'
import { AvailabilityComponent } from '../../components/availability/AvailabilityComponent'
import { ListContentComponent } from '../../components/facilities/ListContentComponent'
import { useSelector } from 'react-redux'
import { getDataFacilities } from '../../services'
import { useNavigate } from 'react-router-dom'
import { ButtonReserveComponent } from '../../components/globals/ButtonReserveComponent'

export const FacilitiesListView = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [facilities, setFacilities] = useState({})
  const dataRedux = useSelector((state) => state.global);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const getFacilities = async () => {
      const response = await getDataFacilities();
      if(response){
        setData(dataRedux.facilities)
        setFacilities(response)
        setLoading(false);
      }else{
        navigate('/error');
      }
    }

    getFacilities();
  }, [])


  if (isLoading) { return <div className='loader'><span className='loading'></span></div> }

  return (
    <>
    <div className='facilities-container'>
      <AvailabilityComponent />
      <ListContentComponent data={data} facilities={facilities} />
    </div>
    < ButtonReserveComponent withButtonSecondary={true} />
    </>
  )
}