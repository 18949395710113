import './App.scss';
import { RouterOutlet } from './router/RouterOutlet';

import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";

import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  changeBookings,
  changeConfiguration,
  changeFacilities,
  changeHome,
  changeServices,
  changeContact,
  changeValues,
  changeFacilitiesOptions
} from './redux/globalSlice';
import { getOptionsFacilities } from './services';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [completedOperations, setCompletedOperations] = useState(0);

  const firebaseConfig = {
    apiKey: "AIzaSyBwqAh6cjb44nKZLElF5q63fOqANENkV4E",
    authDomain: "mantenedor-muruna.firebaseapp.com",
    projectId: "mantenedor-muruna",
    storageBucket: "mantenedor-muruna.appspot.com",
    messagingSenderId: "729678205400",
    appId: "1:729678205400:web:fb04350a4502723ed462ad",
    measurementId: "G-DS3RKT6TJT"
  };

  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000,
  }

  remoteConfig.defaultConfig = {
    "BahiaIsidoro": {}
  };

  const dispatch = useDispatch();

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        try {
          const response = JSON.parse(getValue(remoteConfig, "BahiaIsidoro")._value);
          dispatch(changeHome(response.data.home))
          dispatch(changeBookings(response.data.bookings))
          dispatch(changeServices(response.data.services))
          dispatch(changeFacilities(response.data.facilities))
          dispatch(changeValues(response.data.values))
          dispatch(changeContact(response.data.contact))
          dispatch(changeConfiguration(response.data.configuration))
        }
        catch {
          console.log('error');
        }
      })
      .finally(() => {
        setCompletedOperations((prev) => prev + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  useEffect(() => {
    const getDataOptionsFacilities = async () => {
      const response = await getOptionsFacilities();
      dispatch(changeFacilitiesOptions(response))
      setCompletedOperations((prev) => prev + 1);
    }
    getDataOptionsFacilities();
  }, [])

  useEffect(() => {
    if (completedOperations === 2) {
      setIsLoading(false);
    }
  }, [completedOperations])

  if (isLoading) { return <div className='loader'><span className='loading'></span></div> }

  return (
    <div className="App">
      <div className='app-container'>
        <RouterOutlet />
      </div>
    </div>
  );
}

export default App;