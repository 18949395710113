import API from "./api";
import {
    getAllPricesMock,
    getTextsHomeMock,
    getDataBookingsMock,
    getDataServicesMock,
    getDataFacilitiesMock,
    getDataFacilityMock,
    getDataCompaniesMock,
    getDataGlobalMock,
    getDataContactMock
} from "./mocks"
import axios from "axios";


export const getCheckAvailability = async (params) => {
    let formData = new FormData();    //formdata object
    Object.entries(params).forEach(([key, value]) => formData.append(key, value));
    const response = await API.post("/room/check-availability", formData, {
        headers: {
            "Content-Type": 'multipart/from-data'
        }
    })
        .catch(error => {
            console.error('error al  hacer la solicitud check availability', error);
        })
    return response
};


export const getInstallationTypeList = async () => {
    const response = await API.get("/getInstallationTypeList")
        .catch(error => {
            console.error('error al  hacer la solicitud getInstallationTypeList', error);
        })
    return response.data.data
};

export const getAvailableCabinList = async (params) => {
    let formData = new FormData();    //formdata object
    Object.entries(params).forEach(([key, value]) => formData.append(key, value));
    const response = await API.post("/getAvailableCabinList", formData, {
        headers: {
            "Content-Type": 'multipart/from-data'
        }
    })
        .catch(error => {
            console.error('error al  hacer la solicitud check availability', error);
        })
    return response.data
};


export const getInfoInstallation = async (id) => {
    //let formData = new FormData();    //formdata object
    //Object.entries(params).forEach(([key, value]) => formData.append(key, value));
    /*const response = await API.post("/getAvailableCabinList", formData, {headers: {
        "Content-Type": 'multipart/from-data'
    }})
    .catch(error => {
        console.error('error al  hacer la solicitud check availability', error);
    })
    return response */
};



export const getAllPrices = async () => {
    const API_KEY = "GERlWcHEAT3n0D44d1P3q18nunMuoDMB890D9tjf";
    try {
        const response = await axios.get(
            "https://o35ne1gvfl.execute-api.us-east-2.amazonaws.com/default/bookisity-season-pricing",
            {
                headers: {
                    "x-api-key": API_KEY,
                },
            }
        );
        return response.data.body.pricing_data;
    } catch (error) {
        console.error("Error al hacer la solicitud de season pricing", error);
        return false;
    }
};

export const getUserDetail = async (params) => {
    let formData = new FormData();    //formdata object
    Object.entries(params).forEach(([key, value]) => formData.append(key, value));
    const { data } = await API.post("/site/getuserdetail", formData, {
        headers: {
            "Content-Type": 'multipart/from-data'
        }
    })
        .catch(error => {
            console.error('error al  hacer la solicitud check availability', error);
        })
    return data
};

export const getPricesOfService = async (params) => {
    let formData = new FormData();    //formdata object
    Object.entries(params).forEach(([key, value]) => formData.append(key, value));
    const response = await API.post("/site/book-room-2", formData, {
        headers: {
            "Content-Type": 'multipart/from-data'
        }
    })
        .catch(error => {
            console.error('error al  hacer la solicitud book-room-2', error);
        })
    return response
};


export const getTextsHome = async () => {
    const texts = await getTextsHomeMock();
    return texts
};

export const getDataBookings = async () => {
    const data = await getDataBookingsMock();
    return data
};

export const getDataServices = async () => {
    const data = await getDataServicesMock();
    return data
};

export const getDataFacilities = async () => {
    const API_KEY = "NexoNAXXopaUmUTmWMnUEBMJOWGGIKM1B2CDqzSe";
    try {
        const response = await axios.get('https://xs7s43v469.execute-api.us-east-2.amazonaws.com/default/bookisity-installation-type-list-data', {
            headers: {
                'x-api-key': API_KEY,
                'Content-Type': 'application/json',
            }
        });
        return response.data.body.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return false;
    }
};

export const getOptionsFacilities = async () => {
    try {
        const response = await axios.get('https://80c3zznoch.execute-api.us-east-2.amazonaws.com/default/bookisity-installation-type-list', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data.body;
    } catch (error) {
        console.error('Error fetching data:', error);
        return false;
    }
};

export const sendEmail = async (name, to, subject, text) => {
    const API_KEY = "lnTTUytZ7C5kx4N8XKbOv8T18d3iwLdF4WQBxqGX";
  
    const emailData = {
      name: name,
      to: to,
      subject: subject,
      text: text,
    };
  
    try {
      const response = await axios.post('https://avgm1zj3sa.execute-api.us-east-2.amazonaws.com/default/bookisity-send-email', emailData, {
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      });
  
      console.log("Response sendEmail", response.data);

      return response.data;

    } catch (error) {
      console.error('Error sending email:', error);
      
      throw error;
    }
  };

export const getDataFacility = async (id) => {
    const data = await getDataFacilityMock(id);
    return data
};

export const getDataCompanies = async () => {
    const data = await getDataCompaniesMock();
    return data
};

export const getDataGlobal = async () => {
    const data = await getDataGlobalMock();
    return data
};

export const getDataContact = async () => {
    const data = await getDataContactMock();
    return data
};

export const getBook = async (params) => {
    let formData = new FormData();    //formdata object
    let status = false;
    Object.entries(params).forEach(([key, value]) => formData.append(key, value));
    const response = await API.post("/room/book", formData, {
        headers: {
            "Content-Type": 'multipart/from-data'
        }
    }).then(
        (response) => {
            if (response.data.status !== 0) {
                status = false;
            }
            if (response.data.status === 1) {
                const id = response.data.id;
                status = true;
                window.location.href =`https://payments.bahiaisidoro.cl/bookisityapi/payment?id=${id}`;
                //window.open(`https://payments.bahiaisidoro.cl/bookisityapi/payment?id=${id}`, '_blank');
            }
        })
        .catch(error => {
            console.error('error al  hacer la solicitud getBook', error);
        })
    return status;
};