import React, { useRef } from 'react';
import ImageGallery from 'react-image-gallery';

export const ImagesGalleryComponent = ({ images, onClose }) => {
  const imagesGalery = images.map((url) => ({
    original: url,
    thumbnail: url
  }));

  const galleryRef = useRef(null);

  const handlePrevClick = () => {
    if (galleryRef.current) {
      galleryRef.current.slideToIndex(galleryRef.current.getCurrentIndex() - 1);
    }
  };

  const handleNextClick = () => {
    if (galleryRef.current) {
      galleryRef.current.slideToIndex(galleryRef.current.getCurrentIndex() + 1);
    }
  };

  return (
    <div className='images-gallery-container'>
      <div className='images-gallery-content'>
        <div className='close'>
          <button className='close' onClick={onClose}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z" fill="#1E1E1E" />
            </svg>
          </button>
        </div>
        <button className='prev-arrow' onClick={handlePrevClick}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7515 18.1903L8.28796 10.8071L15.6711 3.34363" stroke="#FF6200" strokeWidth="2.06207" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <button className='next-arrow' onClick={handleNextClick}>
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99853 18.1903L16.462 10.8071L9.07886 3.34363" stroke="#FF6200" strokeWidth="2.06207" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <ImageGallery
          ref={galleryRef}
          className='images-gallery'
          items={imagesGalery}
          showFullscreenButton={false}
          showPlayButton={false}
          showNav={false}
        />
      </div>
    </div>
  );
};