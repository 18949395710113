import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { priceToCLP } from '../../utils/utils';

export const BookingsConfirmComponent = (props) => {
  const [dataReserve, setDataReserve] = useState({});
  const [data, setData] = useState({});
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storage = JSON.parse(sessionStorage.getItem('dataReserve'));
    if (storage) {
      setDataReserve(storage);
    }
  }, [])

  useEffect(() => {
    props.data.facilities.forEach(item => {
      if (item.id === dataReserve.tipo) {
        setData(item);
      }
    });
  }, [props.data]);

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleVolverAtras = () => {
    navigate(-1);
  };

  return (
    <>
      <div className='stepper-wrapper stage3'>
        <div className='stepper'>
          <div className='step1'></div>
          <div className='bar bar1'></div>
          <div className='step2'></div>
          <div className='bar bar2'></div>
          <div className='step3'></div>
        </div>
        <div className='text'>
          <p className='text1'>Selecciona la instalación</p>
          <p className='text2'>Completa los datos</p>
          <p className='text3'>¡Está lista tu reserva!</p>
        </div>
      </div>
      <div className='button-container-back'>
        <button onClick={handleVolverAtras} className='bi-button-hyperlink back-button'>
          <span></span>
          Volver
        </button>
      </div>
      <p className='title'>¡Tu reserva está casi lista!</p>
      <div className='alert alert-info'>
        <span className='symbol' style={{ width: '40px' }}></span>
        <div className='text'>
          <p>Antes de confirmar, revisa que tus datos estén correctos.</p>
        </div>
      </div>

      <div className='card-container'>
        <div className='card' style={{ marginRight: '24px' }}>
          <p className='title'>Tu selección</p>
          <div className='desc-content'>
            <img src={dataReserve.facility && dataReserve.facility.imageGalery[0]} />
            <div>
              <p dangerouslySetInnerHTML={{ __html: dataReserve.facility && dataReserve.facility.title }} />
              {dataReserve.facility && dataReserve.facility.characteristics.map((characteristic, index) => (
                <div className='characteristics' key={index}>
                  <span dangerouslySetInnerHTML={{ __html: characteristic.icon }} />
                  <p dangerouslySetInnerHTML={{ __html: characteristic.text }} />
                </div>
              ))}
            </div>
          </div>
          <div className='box-row'>
            <p className='light'>Llegada</p>
            <p className='bold'>{dataReserve.entrada}</p>
          </div>
          <div className='box-row'>
            <p className='light'>Salida</p>
            <p className='bold'>{dataReserve.salida}</p>
          </div>
          <div className='box-row'>
            <p className='light'>Cantidad de personas</p>
            <p className='bold'>{`${dataReserve.adultos} adultos, ${dataReserve.ninos} niños`}</p>
          </div>
          <p className='bold'>Información del precio</p>
          <div className='box-row'>
            <p className='light'>{`${priceToCLP(dataReserve.price / dataReserve.daysDifference)} x ${dataReserve.daysDifference} noches`}</p>
            <p className='bold'>{priceToCLP(dataReserve.price)}</p>
          </div>
          <div className='divider' style={{ marginBottom: '24px' }}></div>
          <p className='title'>Tus datos</p>
          <p className='bold'>Titular de la reserva</p>
          <p className='light'>{`${dataReserve.nombres} ${dataReserve.apellidos}`}</p>
          <p className='light'>{dataReserve.rut}</p>
          <p className='bold'>Datos de contacto</p>
          <p className='light'>{dataReserve.telefono}</p>
          <p className='light'>{dataReserve.email}</p>

        </div>
        <div className='card'>
          <p className='title'>Términos y condiciones</p>
          <p className='light' style={{ margin: '16px' }}>
            Lee los términos y condiciones de tu reserva
            <span style={{ color: '#FF6200', textDecoration: 'underline' }}> aquí</span>
          </p>
          <div className='box-row'>
            <div className='checkbox-container'>
              <label className="check-wrapper">
                <input id="checkBox" type="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                <span className="customCheck"></span>
              </label>
            </div>
            <p className='light' style={{ margin: '0' }}>He leído y estoy de acuerdo con los Términos y Condiciones.</p>
          </div>
        </div>
      </div>
      <div className='buttons'>
        <button className='bi-button-secondary' onClick={handleVolverAtras}>Volver al paso anterior</button>
        <button className='bi-button-primary' style={{ marginLeft: '24px' }} disabled={!isCheckboxChecked}>
          Confirmar y pagar
        </button>
      </div>
    </>
  )
}