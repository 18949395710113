import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeight } from '../../redux/buttonReserveSlice';

export const ButtonReserveComponent = (props) => {

    const ref = useRef(null)
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(setHeight(ref.current.clientHeight))
    })

    return (
        <div className='button-reserve-fixed' ref={ref}>
            <Link to={'/reservas'} className='bi-button-primary'>Quiero reservar</Link>
            {props.withButtonSecondary && (
                <Link to={'/'} className='bi-button-tertiary'>Ir al home</Link>
            )}
        </div>
    )
}