import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    home: {
        title: ``,
        subtitle: ``,
        targets: [
            {
                title: ``,
                description: ``,
                textLink: '',
                url: '',
                icon: ``,
                backgroundIcon: ''
            }
        ]
    },
    bookings: {},
    services: {},
    facilities: {},
    values: {},
    contact: {},
    configuration: {},
    facilitiesOptions: {}
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        changeHome: (state, action) => {
            state.home = action.payload;
        },
        changeBookings: (state, action) => {
            state.bookings = action.payload;
        },
        changeServices: (state, action) => {
            state.services = action.payload;
        },
        changeFacilities: (state, action) => {
            state.facilities = action.payload;
        },
        changeValues: (state, action) => {
            state.values = action.payload;
        },
        changeContact: (state, action) => {
            state.contact = action.payload;
        },
        changeConfiguration: (state, action) => {
            state.configuration = action.payload;
        },
        changeFacilitiesOptions: (state, action) => {
            state.facilitiesOptions = action.payload;
        }
    }
})

export const {
    changeHome,
    changeBookings,
    changeServices,
    changeFacilities,
    changeValues,
    changeContact,
    changeConfiguration,
    changeFacilitiesOptions
} = globalSlice.actions;

export default globalSlice.reducer;